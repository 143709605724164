<script setup>
import Icon from '@/Components/Icon.vue';
import calendar from '@i/calendar_today-white-on-black.svg';
import hourglass from '@i/hourglass_empty-white-on-black.svg';
import scheduleBlack from '@i/schedule-white-on-black.svg';
import { inject } from 'vue';

defineProps({
    shift: Object,
});
const dayjs = inject('dayjs');
</script>

<template>
    <div class="grid w-100 grid-cols-2 gap-2">
        <div class="flex items-center gap-2">
            <Icon :icon="calendar" /><span>{{ dayjs(shift.start).format('dd D MMM') }}</span>
        </div>
        <div class="flex items-center gap-2 font-bold">
            <Icon :icon="calendar" /><span>{{ shift.selected_applicant.display_name }}</span>
        </div>
        <div class="flex items-center gap-2">
            <Icon :icon="scheduleBlack" /><span> {{ $t('Start time') }}: {{ dayjs(shift.start).format('H:mm') }}</span>
        </div>
        <div class="flex items-center gap-2">
            <Icon :icon="scheduleBlack" /><span> {{ $t('End time') }}: {{ dayjs(shift.end).format('H:mm') }}</span>
        </div>
        <div class="flex items-center gap-2">
            <Icon :icon="hourglass" /><span>
                {{ (dayjs(shift.end).diff(dayjs(shift.start), 'second') - shift.break) / 3600 }}
                {{ $t('hours') }}</span>
        </div>
    </div>
</template>